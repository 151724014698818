
import SessionsService from '../services/sessions.sevice'

export default class ProfileController {
    constructor() {
    }

    getUserSessions(userId) {
        return SessionsService.getUserSessions(userId)
    }

    deleteUserSessions(caseSessionId) {
        return SessionsService.deleteUserSessions(caseSessionId)
    }
}