import axios from 'axios';
import config from "../../config";
import authHeader from "./auth-header";

const API_URL = config.API+'/caseSession';

class SessionsService {

    getUserSessions(userId) {
        return axios.get(API_URL + `/getByUserId/${userId}`, { headers: authHeader() });
    }

    deleteUserSessions(caseSessionId) {
        //return axios.post(API_URL + `/${caseSessionId}`,  {}, { headers: authHeader() })
        return axios.delete(API_URL + `/${caseSessionId}`,  {}, { headers: authHeader() })
    }
}

export default new SessionsService();